import React from "react";
import "./MarketItemHeader.css";
const MarketItemHeader = ({ text, date, name, bio, time, price }) => {
  return (
    <div className="market-item-header__container">
      <div className="market-item-header__top">
        <span className="market-item-header__text">{text}</span>
        <span className="market-item-header__name">{name}</span>
        <span className="market-item-header__date">{date}</span>
        <span className="market-item-header__time">{time}</span>
      </div>
      <div className="market-item-header__bottom-first">
        <span className="market-item-header__price">{price} &euro;</span>
        <span className="market-item-header__bio">{bio}</span>
      </div>
    </div>
  );
};

export default MarketItemHeader;
