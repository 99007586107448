import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./MarketItem.css";
import MarketItemHeader from "../MarketItemHeader/MarketItemHeader";
import { BsPlusLg } from "react-icons/bs";
import { CgMathMinus } from "react-icons/cg";
const MarketItem = ({ setCartList, cartList }) => {
  const [additions, setAdditions] = useState(null);
  const location = useLocation();
  const { name, price, metadata, images, id } = location.state || {}; // Accedir a les dades passades
  const navigate = useNavigate();
  useEffect(() => {
    // Cerquem si l'element amb el mateix id existeix a cartList
    const cartItem = cartList.find((item) => item.id === id);

    // Si existeix, usem les additions de cartList, si no, usem les de metadata
    if (cartItem) {
      setAdditions({
        cadires: Number(cartItem.additions?.cadires) || 0,
        taules: Number(cartItem.additions?.taules) || 0,
        llum: Number(cartItem.additions?.llum) || 0,
        burres: Number(cartItem.additions?.burres) || 0,
      });
    } else {
      setAdditions({
        cadires: Number(metadata?.Cadires) || 0,
        taules: Number(metadata?.Taules) || 0,
        llum: Number(metadata?.Llum) || 0,
        burres: Number(metadata?.Burres) || 0,
      });
    }
  }, [metadata, id, cartList]);
  const handleIncrement = (item) => {
    setAdditions((prevAdditions) => ({
      ...prevAdditions,
      [item]: prevAdditions[item] + 1,
    }));
  };

  const handleDecrement = (item) => {
    setAdditions((prevAdditions) => ({
      ...prevAdditions,
      [item]: prevAdditions[item] > 0 ? prevAdditions[item] - 1 : 0,
    }));
  };

  const handleClick = () => {
    const finalPrice =
      price +
      additions?.cadires * metadata?.CadiresPreu +
      additions?.taules * metadata?.TaulesPreu +
      additions?.llum * metadata?.LlumPreu +
      additions?.burres * metadata?.BurresPreu;

    // Crear l'objecte amb metadata actualitzat
    const updatedItem = {
      ...location.state, // Copia de tot el location.state original
      metadata: {
        ...metadata, // Copia del metadata original
        Cadires: additions?.cadires, // Actualitzem amb additions
        Taules: additions?.taules,
        Llum: additions?.llum,
        Burres: additions?.burres,
      },
      additions: {
        // Afegim l'objecte additions al item actualitzat
        cadires: additions?.cadires,
        taules: additions?.taules,
        llum: additions?.llum,
        burres: additions?.burres,
      },
      finalPrice: finalPrice, // Afegir el preu final
    };

    // Comprovar si l'item ja existeix en el cartList
    setCartList((prevCartList) => {
      const itemExists = prevCartList.some(
        (item) => item.id === updatedItem.id
      );

      if (!itemExists) {
        // Si no existeix, afegir-lo
        return [...prevCartList, updatedItem];
      }

      // Si ja existeix, actualitzar les additions i el finalPrice
      return prevCartList.map((item) =>
        item.id === updatedItem.id ? updatedItem : item
      );
    });

    // Redirigir al carret
    navigate("/cart");
  };

  return (
    <div className="market-item__container">
      <img src={metadata?.ImatgeUrl} alt="" className="market-item__image" />
      <div className="market-item__info-container">
        <MarketItemHeader
          name={name}
          bio={metadata?.Bio}
          lights={metadata?.Llums}
          time={metadata?.Hora}
          price={price}
          text={metadata?.text}
          date={metadata?.Data}
        />
        <div className="market-item__additions-container">
          <span className="market-item__additions-text">{metadata?.Text}</span>
          <div className="market-item__additions-row">
            <div className="market-item__additions-row__left">
              <span>{metadata?.CadiresNom}</span>
              <span>{metadata?.CadiresPreu}&euro; ut.</span>
            </div>
            <div className="market-item__additions-row__right">
              <BsPlusLg
                size={30}
                color="var(--tertiary-color)"
                className="market-item__additions-row__sign"
                onClick={() => {
                  handleIncrement("cadires");
                }}
              />
              {additions?.cadires < 10 ? (
                <span>0{additions?.cadires}</span>
              ) : (
                <span>{additions?.cadires}</span>
              )}
              <CgMathMinus
                size={30}
                color="var(--tertiary-color)"
                className="market-item__additions-row__sign"
                onClick={() => {
                  handleDecrement("cadires");
                }}
              />
            </div>
          </div>

          <div className="market-item__additions-row">
            <div className="market-item__additions-row__left">
              <span>{metadata?.TaulesNom}</span>
              <span>{metadata?.TaulesPreu} &euro; ut.</span>
            </div>
            <div className="market-item__additions-row__right">
              <BsPlusLg
                size={30}
                color="var(--tertiary-color)"
                className="market-item__additions-row__sign"
                onClick={() => {
                  handleIncrement("taules");
                }}
              />
              {additions?.taules < 10 ? (
                <span>0{additions?.taules}</span>
              ) : (
                <span>{additions?.taules}</span>
              )}
              <CgMathMinus
                size={30}
                color="var(--tertiary-color)"
                className="market-item__additions-row__sign"
                onClick={() => {
                  handleDecrement("taules");
                }}
              />
            </div>
          </div>

          <div className="market-item__additions-row">
            <div className="market-item__additions-row__left">
              <span>{metadata?.LlumNom}</span>
              <span>{metadata?.LlumPreu} &euro; ut.</span>
            </div>
            <div className="market-item__additions-row__right">
              <BsPlusLg
                size={30}
                color="var(--tertiary-color)"
                className="market-item__additions-row__sign"
                onClick={() => {
                  handleIncrement("llum");
                }}
              />
              {additions?.llum < 10 ? (
                <span>0{additions?.llum}</span>
              ) : (
                <span>{additions?.llum}</span>
              )}
              <CgMathMinus
                size={30}
                color="var(--tertiary-color)"
                className="market-item__additions-row__sign"
                onClick={() => {
                  handleDecrement("llum");
                }}
              />
            </div>
          </div>
          <div className="market-item__additions-row">
            <div className="market-item__additions-row__left">
              <span>{metadata?.BurresNom}</span>
              <span>{metadata?.BurresPreu} &euro; ut.</span>
            </div>
            <div className="market-item__additions-row__right">
              <BsPlusLg
                size={30}
                color="var(--tertiary-color)"
                className="market-item__additions-row__sign"
                onClick={() => {
                  handleIncrement("burres");
                }}
              />
              {additions?.burres < 10 ? (
                <span>0{additions?.burres}</span>
              ) : (
                <span>{additions?.burres}</span>
              )}
              <CgMathMinus
                size={30}
                color="var(--tertiary-color)"
                className="market-item__additions-row__sign"
                onClick={() => {
                  handleDecrement("burres");
                }}
              />
            </div>
          </div>
        </div>
        <div className="market-item__map-container">
          <span className="market-item__map__title">Mapa de les parades</span>
          {images && images[0] && <img src={images[0]} alt="map" />}
          <span className="market-item__map__bottom-text">
            Mercantic assignarà els llocs, pero si tens alguna prefèrencia
            contacta amb xxxxxx@mercantic.com
          </span>
        </div>
        <div className="market-item__add-to-cart__container">
          <div className="market-item__add-to-cart__top">
            <span
              onClick={() => {
                handleClick();
              }}
            >
              Afegir al carret
            </span>
            <span>
              {price +
                additions?.cadires * metadata?.CadiresPreu +
                additions?.taules * metadata?.TaulesPreu +
                additions?.llum * metadata?.LlumPreu +
                additions?.burres * metadata?.BurresPreu}
              &euro;
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketItem;
