import React, { useEffect, useState } from "react";
import { AiOutlineShopping } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import "./Navbar.css";
import { useLocation } from "react-router-dom";

const Navbar = ({ cartList }) => {
  const location = useLocation();
  const [backgroundColor, setBackgroundColor] = useState(
    "var(--primary-color)"
  );
  const [color, setColor] = useState("var(--secondary-color)");
  useEffect(() => {
    if (
      (location.pathname === "/cart" && cartList.length < 0) ||
      location.pathname === "/success"
    ) {
      setBackgroundColor("#C9DBDC");
      setColor("var(--primary-color)");
    } else if (location.pathname !== "/cart" || cartList.length <= 0) {
      setBackgroundColor("var(--primary-color)");
      setColor("var(--secondary-color)");
    }
    //eslint-disable-next-line
  }, [location.pathname]);

  const navigate = useNavigate();
  return (
    <div
      className="navbar__container"
      style={{ backgroundColor: backgroundColor }}
    >
      <span
        style={{ color: color }}
        onClick={() => {
          navigate("/");
        }}
        className="navbar__logo"
      >
        mercantic mercats
      </span>
      <AiOutlineShopping
        className="navbar__icon"
        color={color}
        onClick={() => {
          navigate("/cart");
        }}
      />
    </div>
  );
};

export default Navbar;
