import React from "react";
import "./EmptyCart.css";

const EmptyCart = () => {
  return (
    <div className="empty-cart__container">
      <p className="empty-cart__text">El teu carro és buit</p>
    </div>
  );
};

export default EmptyCart;
