import React, { useState } from "react";
import "./Form.css";
import Input from "../Input/Input";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { loadStripe } from "@stripe/stripe-js";
// Inicialitza Firebase Storage

const Form = ({ cartList }) => {
  // Inicialitza l'state formData com un objecte buit
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    province: "",
    country: "",
    phone: "",
    web: "",
    project: null,
    company: "",
    city: "",
    postalCode: "",
    nif: "",
    email: "",
    socials: "",
  });

  const [emailError, setEmailError] = useState(false);
  const [inputErrors, setInputErrors] = useState({
    name: false,
    phone: false,
    company: false,
    email: false,
    socials: false,
  });
  const [fileName, setFileName] = useState("");

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
  // Funció per manejar els canvis als inputs
  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "project" && files.length > 0) {
      setFileName(files[0].name);
      setFormData((prevData) => ({
        ...prevData,
        project: files[0], // Guarda el fitxer a l'estat
      }));
    }

    // Si l'input és l'email, fem la validació
    if (name === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) {
        setEmailError(true);
      } else {
        setEmailError(false); // Si és vàlid, eliminem l'error
      }
    }

    // Si l'input és un fitxer (PDF o Word), actualitzem l'estat amb el fitxer
    if (name === "project" && files) {
      const acceptedFileTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];
      const file = files[0];

      // Verifiquem que el fitxer sigui PDF o Word
      if (acceptedFileTypes.includes(file.type)) {
        setFormData((prevData) => ({
          ...prevData,
          document: file, // Guardem el fitxer PDF o Word
        }));
      } else {
        alert("Només s'accepten fitxers PDF o Word (.doc, .docx)");
      }
    } else {
      // Actualitzem l'state per a qualsevol altre camp
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const validateForm = () => {
    const errors = {
      name: !formData.name.trim(),
      phone: !formData.phone.trim(),
      company: !formData.company.trim(),
      email: !formData.email.trim(),
      socials: !formData.socials.trim(),
    };
    setInputErrors(errors);

    // Retorna true si no hi ha cap error
    return !Object.values(errors).includes(true);
  };

  const handleCheckout = async () => {
    // Verifica si el formulari és vàlid
    if (!validateForm()) {
      return;
    }

    // Mapeja els articles del carret
    const items = cartList.map((item) => ({
      finalPrice: item.finalPrice,
      quantity: 1,
    }));

    // Crea un FormData amb els items i la informació del client
    const data = new FormData();
    data.append("items", JSON.stringify(items));
    data.append("customerInfo", JSON.stringify(formData));

    // Si hi ha un projecte, l'afegeix al FormData
    if (formData.project) {
      data.append("project", formData.project);
    }

    try {
      // Fes la petició al servidor per crear la sessió de checkout
      const response = await fetch(
        "https://shop-server-f873c36fc4e4.herokuapp.com/create-checkout-session",
        {
          method: "POST",
          body: data,
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();

      // Redirigeix a la finestra de pagament d'Stripe si s'ha generat correctament la sessió
      if (result.id) {
        const stripe = await stripePromise; // Espera que la instància d'Stripe es carregui
        stripe.redirectToCheckout({ sessionId: result.id });
      } else {
        window.location.href = "/cancel";
      }
    } catch (error) {
      console.error("Error durant el procés de checkout:", error);
    }
  };

  // const handleCheckout = async () => {
  //   // Verifica si el formulari és vàlid
  //   if (!validateForm()) {
  //     return;
  //   }

  //   // Mapeja els articles del carret
  //   const items = cartList.map((item) => ({
  //     finalPrice: item.finalPrice,
  //     quantity: 1,
  //   }));

  //   // Crea un FormData amb els items i la informació del client
  //   const data = new FormData();
  //   data.append("items", JSON.stringify(items));
  //   data.append("customerInfo", JSON.stringify(formData));

  //   // Si hi ha un projecte, l'afegeix al FormData
  //   if (formData.project) {
  //     data.append("project", formData.project);
  //   }

  //   try {
  //     // Fes la petició al servidor per crear la sessió de checkout
  //     const response = await fetch(
  //       "https://shop-server-f873c36fc4e4.herokuapp.com/create-checkout-session",
  //       {
  //         method: "POST",
  //         body: data,
  //       }
  //     );

  //     if (!response.ok) {
  //       throw new Error(`HTTP error! status: ${response.status}`);
  //     }

  //     const result = await response.json();

  //     // Redirigeix a la finestra de pagament d'Stripe si s'ha generat correctament la sessió
  //     if (result.id) {
  //       const stripe = await stripePromise; // Espera que la instància d'Stripe es carregui
  //       stripe.redirectToCheckout({ sessionId: result.id });
  //     } else {
  //       window.location.href = "/cancel";
  //     }
  //   } catch (error) {
  //     console.error("Error durant el procés de checkout:", error);
  //   }
  // };

  return (
    <div className="form__container">
      <div className="form__items-container">
        <Input
          type="text"
          placeholder="Nom i Cognoms*"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          mandatory={true}
          inputErrors={inputErrors.name}
        />
        <Input
          type="text"
          placeholder="Adreça"
          name="address"
          value={formData.address}
          onChange={handleInputChange}
          mandatory={false}
        />
        <Input
          type="text"
          placeholder="Provincia"
          name="province"
          value={formData.province}
          onChange={handleInputChange}
          mandatory={false}
        />
        <Input
          type="text"
          placeholder="País"
          name="country"
          value={formData.country}
          onChange={handleInputChange}
          mandatory={false}
        />
        <Input
          type="text"
          placeholder="Telèfon de Contacte*"
          name="phone"
          value={formData.phone}
          onChange={handleInputChange}
          mandatory={true}
          inputErrors={inputErrors.phone}
        />
        <Input
          type="text"
          placeholder="Web"
          name="web"
          value={formData.web}
          onChange={handleInputChange}
          mandatory={false}
        />
        <label htmlFor="file-upload" className="custom-file-upload">
          <span className={fileName ? "label-text" : "label-placeholder"}>
            {fileName === "" ? "Adjuntar Projecte" : fileName}
          </span>
        </label>
        <Input
          type="file"
          id="file-upload"
          placeholder="Adjuntar Projecte"
          name="project"
          onChange={handleInputChange}
          mandatory={false}
          accept=".pdf, .doc, .docx" // Afegeix els formats acceptats
        />
      </div>
      <div className="form__items-container">
        <Input
          type="text"
          placeholder="Nom de l'Empresa*"
          name="company"
          value={formData.company}
          onChange={handleInputChange}
          mandatory={true}
          inputErrors={inputErrors.company}
        />
        <Input
          type="text"
          placeholder="Ciutat"
          name="city"
          value={formData.city}
          onChange={handleInputChange}
          mandatory={false}
        />
        <Input
          type="text"
          placeholder="Codi Postal"
          name="postalCode"
          value={formData.postalCode}
          onChange={handleInputChange}
          mandatory={false}
        />
        <Input
          type="text"
          placeholder="NIF/CIF"
          name="nif"
          value={formData.nif}
          onChange={handleInputChange}
          mandatory={false}
        />
        <div style={{ width: "calc(100%)", position: "relative" }}>
          <Input
            type="text"
            placeholder="Correu Electrònic*"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            emailError={emailError}
            mandatory={true}
            inputErrors={inputErrors.email}
          />
          {emailError && <AiOutlineExclamationCircle className="error-sign" />}
        </div>

        <Input
          type="text"
          placeholder="XXSS*"
          name="socials"
          value={formData.socials}
          onChange={handleInputChange}
          emailError={emailError}
          mandatory={true}
        />
        <div
          className="form__payment-button"
          onClick={() => {
            handleCheckout();
          }}
        >
          Pagament
        </div>
      </div>
    </div>
  );
};

export default Form;
