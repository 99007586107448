import React, { useState, useEffect } from "react";
import MonthTitle from "../MonthTitle/MonthTitle";
import MarketRow from "../MarketRow/MarketRow";
const MonthBlock = ({ month, marketList, cartList, setCartList }) => {
  const [especialMarkets, setEspecialMarkets] = useState([]);
  const [normalMarkets, setNormalMarkets] = useState([]);

  // Usa useEffect per separar el marketList en dues llistes
  useEffect(() => {
    if (marketList) {
      const especial = marketList.filter(
        (market) => market?.metadata?.Type?.toLowerCase() === "especial"
      );
      const normal = marketList.filter(
        (market) => market?.metadata?.Type?.toLowerCase() !== "especial"
      );

      setEspecialMarkets(especial);
      setNormalMarkets(normal);
    }
  }, [marketList]);
  return (
    <div className="month-block__container">
      <MonthTitle month={month} />
      {normalMarkets?.map((market) => {
        return (
          <MarketRow
            info={market}
            cartList={cartList}
            specialRow={false}
            setCartList={setCartList}
          />
        );
      })}
      {especialMarkets.length >= 1 && <MarketRow specialRow={true} />}
      {especialMarkets?.length >= 1 &&
        especialMarkets.map((market) => {
          return (
            <MarketRow
              info={market}
              cartList={cartList}
              specialRow={false}
              setCartList={setCartList}
            />
          );
        })}
    </div>
  );
};

export default MonthBlock;
