import React, { useEffect } from "react";
import "./SuccessPage.css";
const SuccessPage = ({ setCartList }) => {
  useEffect(() => {
    setCartList([]);
    //eslint-disable-next-line
  }, []);
  return (
    <div className="success__container">
      <p className="success__text">
        Hem rebut la teva sol·licitud. El teu projecte està en revisió. Aviat
        ens posarem en contacte amb tu per confirmar la teva plaça.
      </p>
    </div>
  );
};

export default SuccessPage;
