import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useIsMobile from "../../custom-hooks/useIsMobile.js";
import "./MarketRow.css";
const MarketRow = ({ info, specialRow, setCartList }) => {
  const [color, setColor] = useState("var(--primary-color)");
  const [stylesController, setStylesController] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  useEffect(() => {
    if (location.pathname === "/cart") {
      setStylesController(true);
    } else setStylesController(false);
  }, [location.pathname]);
  const handleClick = () => {
    if (!stylesController) {
      navigate(`${info?.name}`, { state: info });
    } else if (stylesController) {
      setCartList((prevCartList) => {
        // Filtra i elimina l'element amb l'info?.id
        return prevCartList.filter((item) => item.id !== info?.id);
      });
    }
  };
  const handleRowClick = () => {
    if (stylesController) {
      navigate(`/${info?.name}`, { state: info }); // Navegar amb la informació del mercat
    }
  };
  if (!isMobile) {
    if (specialRow === false) {
      return (
        <div
          className={
            !stylesController
              ? "market-row__container"
              : "market-row-cart__container"
          }
          onMouseEnter={() => {
            setColor("var(--secondary-color)");
          }}
          onMouseLeave={() => {
            setColor("var(--primary-color)");
          }}
        >
          <div
            className={
              !stylesController ? "market-row__left" : "market-row__left-cart"
            }
          >
            <div style={{ width: "50%" }}>
              <span style={{ color: color }}>
                {info?.metadata?.Data}, {info?.metadata?.Dia}
              </span>
            </div>
            <div style={{ width: "50%" }}>
              <span
                style={{ color: color }}
                className={stylesController ? "cart__redirect" : ""}
                onClick={() => {
                  handleRowClick();
                }}
              >
                {info?.name}
              </span>
            </div>
          </div>
          <div className="market-row__right">
            {!stylesController ? (
              <>
                <span style={{ color: color }}>{info?.price} &euro;</span>
                <span
                  style={{ color: color }}
                  onClick={(e) => {
                    e.stopPropagation(); // Evitar que es trigui el clic de la fila
                    handleClick();
                  }}
                  className="home__add"
                >
                  {info?.metadata?.Button}
                </span>
              </>
            ) : (
              <>
                <span
                  style={{ color: color }}
                  onClick={(e) => {
                    e.stopPropagation(); // Evitar que es trigui la navegació
                    handleClick();
                  }}
                  className="cart__delete"
                >
                  Eliminar
                </span>
                <span style={{ color: color }}>{info?.finalPrice} &euro;</span>
              </>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="market-row__container-special"
          onClick={() => {
            handleRowClick();
          }}
        >
          <span style={{ color: color }}>Especials</span>
        </div>
      );
    }
  } else if (isMobile) {
    if (specialRow === false) {
      return (
        <div
          className={
            !stylesController
              ? "market-row__container"
              : "market-row-cart__container"
          }
          onMouseEnter={() => {
            setColor("var(--secondary-color)");
          }}
          onMouseLeave={() => {
            setColor("var(--primary-color)");
          }}
          onClick={handleRowClick} // Navegar a la informació del mercat
        >
          <div className="market-row__left">
            <span style={{ color: color }}>{info?.metadata?.Data}</span>
            <span style={{ color: color }}>{info?.name}</span>
            <span style={{ color: color }}>{info?.price} &euro;</span>
          </div>
          <div className="market-row__right">
            {!stylesController ? (
              <>
                <span
                  style={{ color: color }}
                  onClick={(e) => {
                    e.stopPropagation(); // Evitar que es trigui el clic de la fila
                    handleClick();
                  }}
                >
                  {info?.metadata?.Button}
                </span>
              </>
            ) : (
              <>
                <span
                  style={{ color: color }}
                  onClick={(e) => {
                    e.stopPropagation(); // Evitar que es trigui la navegació
                    handleClick();
                  }}
                >
                  Eliminar
                </span>
                {/* <span style={{ color: color }}>{info?.price} &euro;</span> */}
              </>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="market-row__container-special"
          onClick={() => {
            handleRowClick();
          }}
        >
          <span>Especials</span>
        </div>
      );
    }
  }
};

export default MarketRow;
