import { useState, useEffect } from "react";

const useIsMobile = () => {
  // Defineix una mida màxima per considerar un dispositiu mòbil
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    // Funció que actualitza l'state segons la mida de la finestra
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Afegeix l'event listener per escoltar els canvis en la mida de la finestra
    window.addEventListener("resize", handleResize);

    // Executa la funció un cop inicialment per garantir la mida correcta
    handleResize();

    // Cleanup: elimina l'event listener quan el component es desmunta
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isMobile;
};

export default useIsMobile;
