import React, { useState, useEffect } from "react";
import "./Home.css";
import MonthBlock from "../MonthBlock/MonthBlock";
import homeImage from "../../assets/homeMarket.jpg";

const Home = ({ cartList, setCartList }) => {
  const [markets, setMarkets] = useState(null);

  const fetchProductsWithPrices = async () => {
    try {
      const response = await fetch(
        "https://shop-server-f873c36fc4e4.herokuapp.com/api/products-with-prices"
      ); // Crida al teu endpoint del backend
      const data = await response.json();

      // Filtra només productes actius
      const activeProducts = data.filter((product) => product.active === true);

      return activeProducts; // Retorna els productes actius amb preus (ja inclou preus)
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const groupByBlock = (markets) => {
    return markets?.reduce((acc, market) => {
      const block = market?.metadata?.Block;

      if (!acc[block]) {
        acc[block] = []; // Si no existeix aquest block, el creem com a array buit
      }
      acc[block].push(market); // Afegim el mercat actual a l'array corresponent al block
      return acc;
    }, {});
  };

  useEffect(() => {
    const loadMarkets = async () => {
      // Fa la crida a l'API sempre
      const products = await fetchProductsWithPrices();
      const groupedMarkets = groupByBlock(products);
      setMarkets(groupedMarkets);
    };

    loadMarkets(); // Executem la funció per carregar els mercats
    //eslint-disable-next-line
  }, []);

  return (
    <div className="home__container">
      <img src={homeImage} alt="" className="home__image" />

      {markets &&
        Object.entries(markets)
          .sort(([a], [b]) => a - b)
          .map(([blockKey, blockMarkets]) => (
            <MonthBlock
              key={blockKey}
              marketList={blockMarkets}
              month={blockMarkets[0]?.metadata?.Mes}
              cartList={cartList}
              setCartList={setCartList}
            />
          ))}
    </div>
  );
};

export default Home;
