import React from "react";
import "./ShoppingCart.css";
import MarketRow from "../MarketRow/MarketRow";
import Form from "../Form/Form";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import useIsMobile from "../../custom-hooks/useIsMobile";
const ShoppingCart = ({ cartList, setCartList }) => {
  const isMobile = useIsMobile();
  return (
    <div className="shopping-cart__container">
      <div className="shopping-cart__list">
        {cartList.map((item) => {
          return (
            <MarketRow
              info={item}
              specialRow={false}
              setCartList={setCartList}
            />
          );
        })}
      </div>
      <Form setCartList={setCartList} cartList={cartList} />
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          backgroundColor: "var(--secondary-color)",
        }}
      >
        <div className="shopping-cart__valid-container">
          <div className="shopping-cart__valid-container-wrapper">
            <div
              style={!isMobile ? { width: "50%" } : { display: "none" }}
            ></div>
            <div
              style={
                !isMobile
                  ? { width: "50%", display: "flex", gap: "4px" }
                  : { width: "100%", display: "flex", gap: "4px" }
              }
            >
              <span>*</span>
              <span>Emplenar camps obligatoris</span>
            </div>
          </div>

          <div className="shopping-cart__valid-container-wrapper">
            <div
              style={!isMobile ? { width: "50%" } : { display: "none" }}
            ></div>
            <div
              style={
                !isMobile
                  ? { width: "50%", display: "flex", gap: "4px" }
                  : {
                      width: "100%",
                      display: "flex",
                      gap: "4px",
                      alignItems: "center",
                    }
              }
            >
              <AiOutlineExclamationCircle color="var(--primary-color)" />
              <span>Camp invàlid</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShoppingCart;
