import React from "react";
import "./Input.css";

const Input = ({
  type,
  placeholder,
  name,
  value,
  onChange,
  emailError,
  inputErrors,
  id,
}) => {
  return (
    <input
      type={type}
      placeholder={placeholder}
      className={`input ${inputErrors || emailError ? "error" : ""}`}
      style={name === "project" ? { display: "none" } : undefined}
      id={id}
      name={name}
      value={name === "project" ? undefined : value}
      onChange={onChange}
    />
  );
};

export default Input;
