import React from "react";
import "./MonthTitle.css";
const MonthTitle = ({ month }) => {
  return (
    <div className="month-title__container">
      <span>{month}</span>
    </div>
  );
};

export default MonthTitle;
