import { useState, useEffect } from "react";
import "./App.css";
import Home from "./components/Home/Home.jsx";
import MarketItem from "./components/MarketItem/MarketItem.jsx";
import Navbar from "./components/Navbar/Navbar.jsx";
import { Routes, Route } from "react-router-dom";
import ShoppingCart from "./components/ShoppingCart/ShoppingCart.jsx";
import SuccessPage from "./components/SuccessPage/SuccessPage.jsx";
import EmptyCart from "./components/EmptyCart/EmptyCart.jsx";
function App() {
  const [cartList, setCartList] = useState(() => {
    const storedCart = localStorage.getItem("cartList");
    return storedCart ? JSON.parse(storedCart) : [];
  });

  // Actualitzar el localStorage cada vegada que cartList canviï
  useEffect(() => {
    localStorage.setItem("cartList", JSON.stringify(cartList));
  }, [cartList]);
  return (
    <>
      <Navbar cartList={cartList} />
      <Routes>
        <Route
          path="/"
          element={<Home setCartList={setCartList} cartList={cartList} />}
        />
        <Route
          path="/:market"
          element={<MarketItem setCartList={setCartList} cartList={cartList} />}
        />
        {cartList.length !== 0 && (
          <Route
            path="/cart"
            element={
              <ShoppingCart cartList={cartList} setCartList={setCartList} />
            }
          />
        )}
        {cartList.length === 0 && (
          <Route path="/cart" element={<EmptyCart />} />
        )}
        <Route
          path="/success"
          element={<SuccessPage setCartList={setCartList} />}
        />
      </Routes>
    </>
  );
}

export default App;
